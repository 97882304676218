import { ChartData, ChartOptions } from 'chart.js'
import dayjs, { QUnitType } from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import { sum } from 'ramda'

import type { LandlordEmailWithFlaggedEmail } from '../Fragments'
import {
  SABucketOptions,
  SADateFilterOptions,
} from '../SentimentAnalysisHelper'

dayjs.extend(weekOfYear)

export const MAX_NUMBER_OF_NODES = 20
export const MIN_NUMBER_OF_NODES = 2

export const getSentimentGraphOptions = (
  title: string,
  filterDateRange: SADateFilterOptions,
  bucketOption: SABucketOptions,
) => {
  const bucket = handleBucket(filterDateRange, bucketOption)
  return {
    responsive: true,
    maintainAspectRatio: true,
    animations: {
      tension: {
        duration: 1000,
        easing: 'linear',
        loop: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        tension: 0.1,
        borderWidth: 1,
      },
    },
    scales: {
      y: {
        position: 'left',
        min: 0,
        max: 10,
        beginAtZero: true,
        type: 'linear',
        display: true,
        title: {
          display: true,
          text: title,
        },
        grid: {
          display: false,
          drawTicks: false,
        },
        ticks: {
          padding: 5,
        },
      },
      totalEmails: {
        label: 'Total Emails',
        beginAtZero: true,
        type: 'linear',
        position: 'right',
        title: {
          display: true,
          text: 'Emails',
        },
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
        },
        ticks: {
          padding: 5,
        },
      },
      x: {
        type: 'time',
        time: {
          unit: bucket,
          displayFormats: {
            hour: 'hA',
            day: 'DD MMM',
            week: 'DD MMM',
            month: 'MMM YY',
            year: 'YYYY',
          },
        },
        title: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#444',
        bodyColor: '#444',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 12,
        callbacks: {
          title: (context) => {
            const emailCount = context[3]?.formattedValue ?? '1'
            const label =
              emailCount === '1'
                ? dayjs(context[0]?.label).format('D MMM YYYY - h:mmA')
                : dayjs(context[0]?.label).format('D MMM YYYY')

            return `${label} - ${context[3]?.formattedValue ?? 1} Emails`
          },
          label: (context) => {
            if (context.datasetIndex === 3) {
              return ''
            } else {
              return context.dataset.label + ': ' + context.parsed.y
            }
          },
        },
      },
    },
    interaction: {
      mode: 'index',
      axis: 'x',
      intersect: false,
    },
  } as ChartOptions<'line'>
}

const getBucketData = (
  emails: LandlordEmailWithFlaggedEmail[],
  bucketOptions: SABucketOptions,
) => {
  const data = []
  for (const email of emails) {
    const date = dayjs(email.receivedAt).format('YYYY-MM-DD')
    const bucket = data.find((bucket) =>
      dayjs(bucket.date).isSame(date, bucketOptions as QUnitType),
    )
    if (bucket) {
      bucket.emails.push(email)
    } else {
      data.push({ date, emails: [email] })
    }
  }

  return data
}

// bucket handler
export const bucketHashMap = new Map([
  [
    SABucketOptions.HOUR,
    [
      SADateFilterOptions.TODAY,

      SADateFilterOptions.WEEK,
      SADateFilterOptions.MONTH,
      SADateFilterOptions.YEAR,
      SADateFilterOptions.ALL,
    ],
  ],
  [
    SABucketOptions.DAY,
    [
      SADateFilterOptions.TODAY,

      SADateFilterOptions.WEEK,
      SADateFilterOptions.MONTH,
      SADateFilterOptions.YEAR,
      SADateFilterOptions.ALL,
    ],
  ],
  [
    SABucketOptions.WEEK,
    [
      SADateFilterOptions.TODAY,

      SADateFilterOptions.WEEK,
      SADateFilterOptions.MONTH,
      SADateFilterOptions.YEAR,
      SADateFilterOptions.ALL,
    ],
  ],
  [
    SABucketOptions.MONTH,
    [
      SADateFilterOptions.TODAY,

      SADateFilterOptions.WEEK,
      SADateFilterOptions.MONTH,
      SADateFilterOptions.YEAR,
      SADateFilterOptions.ALL,
    ],
  ],
  [
    SABucketOptions.YEAR,
    [
      SADateFilterOptions.TODAY,

      SADateFilterOptions.WEEK,
      SADateFilterOptions.MONTH,
      SADateFilterOptions.YEAR,
      SADateFilterOptions.ALL,
    ],
  ],
])

const handleBucket = (
  filterDateRange: SADateFilterOptions,
  bucketOption: SABucketOptions,
) => {
  const bucketOptions = bucketHashMap.get(bucketOption)
  if (bucketOptions?.includes(filterDateRange)) {
    return bucketOption
  } else {
    for (const [key, value] of bucketHashMap.entries()) {
      if ((value as unknown as SADateFilterOptions) === filterDateRange)
        return key
    }
  }
}

export const generateNodeData = (
  emails: LandlordEmailWithFlaggedEmail[],
  filterDateRange: SADateFilterOptions,
  bucketOption: SABucketOptions,
) => {
  const sortedData = emails.sort((a, b) => {
    if (a.receivedAt < b.receivedAt) return 1
    if (a.receivedAt > b.receivedAt) return -1
    return 0
  })
  if (filterDateRange === SADateFilterOptions.TODAY) {
    const data = sortedData.map((email) => ({
      x: email?.receivedAt,
      y: email?.csiRating,
    }))

    return { data }
  }

  // bucket the data into daily buckets this should be an array not an object
  const bucketData = getBucketData(emails, bucketOption)

  const summarizedData = bucketData.map((bucket) => {
    const sortedBucket = bucket.emails.sort((a, b) =>
      a.csiRating > b.csiRating ? 1 : -1,
    )
    const date = bucket.date
    const high = sortedBucket?.at(-1)?.csiRating ?? 0
    const low = sortedBucket?.at(0)?.csiRating ?? 0
    const count = sortedBucket?.length
    const avg = Math.round(
      sum(sortedBucket?.map((email) => email.csiRating)) / count,
    )
    const totalEmails = sortedBucket?.length

    return { date, high, low, count, avg, totalEmails }
  })

  // map the data
  const data = summarizedData.map((email) => ({
    x: email?.date,
    y: email?.avg,
  }))

  const count = summarizedData.map((email) => ({
    x: email?.date,
    y: email?.count,
  }))
  const high = summarizedData.map((email) => ({
    x: email?.date,
    y: email?.high,
  }))
  const low = summarizedData.map((email) => ({
    x: email?.date,
    y: email?.low,
  }))

  const totalEmails = summarizedData.map((email) => ({
    x: email?.date,
    y: email?.totalEmails,
  }))

  return { data, count, high, low, totalEmails }
}

export const getDataFilledChartConfig = (
  filterDateRange: SADateFilterOptions,
  emails: LandlordEmailWithFlaggedEmail[],
  bucketOption: SABucketOptions,
) => {
  const bucket = handleBucket(filterDateRange, bucketOption)
  const nodeData = generateNodeData(emails, filterDateRange, bucket)

  let highest = nodeData.high
  let lowest = nodeData.low
  let count = nodeData.count
  let labels = []
  let average = nodeData.data
  let totalEmails = nodeData.totalEmails

  // The labels and results come back the wrong way the silly billy :P
  if (filterDateRange !== SADateFilterOptions.TODAY) {
    labels = labels.reverse()
    highest = highest.reverse()
    average = average.reverse()
    lowest = lowest.reverse()
    count = count.reverse()
    totalEmails = totalEmails.reverse()
  }

  // Reverse Items to get the desired order
  return {
    labels,
    datasets: [
      {
        label: 'Highest Score',
        data: highest,
        borderColor: '#2563eb',
        backgroundColor: 'rgba(59, 130, 246, .3)',
        pointBackgroundColor: 'rgba(59, 130, 246, 1)',
        fill: '1',
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 4,
      },
      {
        label: 'Average Score',
        data: average,
        borderColor: '#4f46e5',
        backgroundColor: '#4f46e5',
        borderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 4,
      },
      {
        label: 'Lowest Score',
        data: lowest,
        borderColor: '#2563eb',
        backgroundColor: 'rgba(59, 130, 246, .1)',
        fill: '1',
        pointBackgroundColor: 'rgba(59, 130, 246, 1)',
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 4,
      },
      {
        data: count,
        borderColor: 'rgba(255,255,255,0)',
        backgroundColor: 'rgba(255,255,255,0)',
        fill: '-1',
        pointRadius: 0,
      },
      {
        label: 'Total Emails',
        data: totalEmails,
        borderColor: 'rgba(0, 0, 0, 0.8)',
        backgroundColor: 'rgba(0, 0, 0, 1)',
        borderDash: [5, 5],
        borderWidth: 2,
        pointStyle: 'circle',
        pointRadius: 2,
        pointHoverRadius: 4,
        yAxisID: 'totalEmails',
      },
    ],
  } as ChartData<'line'>
}
