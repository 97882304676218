import { ChangeEvent, FC, useEffect, useState } from 'react'

import { Pagination, Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import type { FindSentimentAnalysisQuery } from 'types/graphql'

import type { LandlordEmailWithFlaggedEmail } from '../Fragments'

import SentimentAnalysisEmailHeader from './SentimentAnalysisEmailHeader'

const getPaginatedEmails = <T,>(
  emails: T[],
  paginationIndex: number,
  pageLimit: number,
) => {
  const end = paginationIndex * pageLimit
  const start = end - pageLimit

  return emails.slice(start, end) as T[]
}

interface SentimentAnalysisPaginatedListProps {
  emails: LandlordEmailWithFlaggedEmail[]
  isAdminView?: boolean
  memberships?: FindSentimentAnalysisQuery['memberships']
}

const SentimentAnalysisPaginatedList: FC<
  SentimentAnalysisPaginatedListProps
> = ({ emails, isAdminView, memberships }) => {
  const totalEmailCount = emails?.length
  const pageLimit = 100

  const paginationLimit = Math.ceil(totalEmailCount / pageLimit)

  const [page, setPage] = useState(1)
  const handleChange = (_event: ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const [paginatedEmails, setPaginatedEmails] = useState(
    getPaginatedEmails(emails, page, pageLimit),
  )
  // Max index for current page
  const upperLimit = page * pageLimit

  // Page Start Label
  const currentPageStart = upperLimit - (pageLimit - 1)

  // Page End Label
  const currentPageEnd =
    upperLimit > totalEmailCount ? totalEmailCount : upperLimit

  // Loading state between pages
  const [isLoading, setIsLoading] = useState(true)

  // After emails were updated, reset loading state
  useEffect(() => {
    // wait a moment before setting loading to false simulate call to db to be done later
    setTimeout(() => {
      setIsLoading(false)
    }, 800)
  }, [paginatedEmails, emails])

  useEffect(() => {
    setIsLoading(true)
    setPaginatedEmails(getPaginatedEmails(emails, page, pageLimit))
  }, [page])

  useEffect(() => {
    setPaginatedEmails(getPaginatedEmails(emails, page, pageLimit))
  }, [emails])

  return (
    <div
      className={`flex flex-grow flex-col gap-0 overflow-y-auto border-r ${
        isAdminView ? 'h-full' : 'max-h-[470px]'
      }`}
    >
      {isLoading && (
        <div className="grid h-full gap-0.5 overflow-y-hidden">
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton
              key={index}
              variant={'rectangular'}
              animation={'wave'}
              className={'h-12 w-full'}
              sx={{
                backgroundColor: '#f3f4f6',
              }}
            />
          ))}
        </div>
      )}

      {!isLoading && (
        <>
          {paginatedEmails.map((email) => {
            const displayDate = dayjs(email?.receivedAt).format(
              'DD/MM/YYYY h:mma',
            )

            return (
              <div
                key={email.id}
                className="rounded-none border-0 border-b bg-white px-4 py-3 hover:bg-gray-50"
              >
                <SentimentAnalysisEmailHeader
                  id={email?.id}
                  title={displayDate}
                  csiRating={email?.csiRating}
                  isAdminView={isAdminView}
                  email={email}
                  flaggedEmail={email?.flaggedEmail}
                  memberships={memberships}
                  enableFlagEmailButton={true}
                  settingsHomeView={false}
                />
              </div>
            )
          })}
        </>
      )}

      {emails.length > pageLimit && (
        <div className="sticky bottom-0 z-10 flex items-center justify-between border-y border-r bg-white p-2 px-4">
          <p className="text-sm text-gray-500">
            {`${currentPageStart} - ${currentPageEnd} of ${totalEmailCount}`}
          </p>
          <Pagination
            count={paginationLimit}
            variant="outlined"
            shape="rounded"
            page={page}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

export default SentimentAnalysisPaginatedList
