import { FC } from 'react'

import { Skeleton, Tooltip } from '@mui/material'
import { calculatePercentageOf } from 'api/src/common/utils'

import {
  SentimentAnalysisCSIScoreProps,
  SentimentAnalysisMetric,
} from '../SentimentAnalysisHelper'

const SentimentAnalysisCSIScore: FC<SentimentAnalysisCSIScoreProps> = ({
  overallScore = 0,
  statusCounts = { high: 0, mid: 0, low: 0 },
  hideCounts,
  tooltip = '',
  loading = false,
}) => {
  const totalCount = statusCounts.high + statusCounts.mid + statusCounts.low

  const highPercent =
    totalCount > 0
      ? calculatePercentageOf(0, statusCounts.high, totalCount) + '%'
      : 0
  const midPercent =
    totalCount > 0
      ? calculatePercentageOf(0, statusCounts.mid, totalCount) + '%'
      : 0
  const lowPercent =
    totalCount > 0
      ? calculatePercentageOf(0, statusCounts.low, totalCount) + '%'
      : 0

  return (
    <div className="flex flex-row items-center gap-4">
      <Tooltip
        title={<span className="text-sm">{tooltip}</span>}
        placement="top"
        arrow
        followCursor
      >
        <div className="mr-2 flex items-center gap-1">
          <span className="flex items-center">
            <b>
              {!hideCounts && 'Overall'} {SentimentAnalysisMetric.esi}:
            </b>
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={50}
                height={30}
                className="ml-1 rounded-sm"
              />
            ) : (
              <span className="pl-1 text-3xl">{overallScore}</span>
            )}
          </span>
        </div>
      </Tooltip>

      {!hideCounts && (
        <div className="flex w-[200px] flex-col gap-1">
          {loading ? (
            <div className="flex flex-col gap-1">
              <Skeleton
                variant="rectangular"
                width={200}
                height={10}
                className="rounded-sm"
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={10}
                className="rounded-sm"
              />
              <Skeleton
                variant="rectangular"
                width={200}
                height={10}
                className="rounded-sm"
              />
            </div>
          ) : (
            <>
              <Tooltip
                title={
                  <span className="text-sm">{statusCounts.low} Contacts</span>
                }
                placement="top"
                arrow
              >
                <div className={'flex h-2 flex-row items-center'}>
                  <span
                    style={{ width: lowPercent }}
                    className="rounded bg-red-500 p-[4px]"
                  ></span>
                  <span className={'pl-1 text-xs font-semibold'}>
                    {lowPercent}
                  </span>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <span className="text-sm">{statusCounts.mid} Contacts</span>
                }
                placement="top"
                arrow
              >
                <div className={'flex h-2 flex-row items-center'}>
                  <span
                    style={{ width: midPercent }}
                    className="rounded bg-yellow-500 p-[4px]"
                  ></span>
                  <span className={'pl-1 text-xs font-semibold'}>
                    {midPercent}
                  </span>
                </div>
              </Tooltip>
              <Tooltip
                title={
                  <span className="text-sm">{statusCounts.high} Contacts</span>
                }
                placement="top"
                arrow
              >
                <div className={'flex h-2 flex-row items-center'}>
                  <span
                    style={{ width: highPercent }}
                    className="rounded bg-green-500 p-[4px]"
                  ></span>
                  <span className={'pl-1 text-xs font-semibold'}>
                    {highPercent}
                  </span>
                </div>
              </Tooltip>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default SentimentAnalysisCSIScore
